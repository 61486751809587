@font-face {
  font-family: 'Reenie Beanie';
  src: local('Reenie_Beanie'), url(./fonts/Reenie_Beanie/ReenieBeanie-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Charmonman';
  src: local('Charmonman'), url(./fonts/Charmonman/Charmonman-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open_Sans'), url(./fonts/Open_Sans/OpenSans-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
