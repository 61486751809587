.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} 

@media screen and (max-width:735px) {
  /* HOME PAGE */
  .main_logo .dosoutddors_logo_home {
    width:100%; /* The width is 100%, when the viewport is 730px or smaller */
  }
  #root .App nav ul a{
    font-size: 7px;
  }

  .main_logo .dosoutddors_logo_home{
    margin-top: 120px;
  }

  #root .hamburger_icon{
    display: flex;
  }

  .navBtn{
    display:none;
  }

  .App > nav {
    height: 54px;
  }

  div#BurgerMenu{
    width:100%;
  }
  #page-body .welcome_right{
    padding: 20px 20px 20px 20px; 
  }

  #page-body .intro_section {
    box-shadow: none;
  }

  #page-body .aspectBox_text{
    font-size:40px;
  }

  /* ARTICLE PAGE */
  #root #page-body{
    width: 85%;
  }

  #page-body .article_content_container .article_paragraph, #page-body .article_content_container li, #page-body .article_content_container  .bold_article_paragraph {
    font-size: 14px;
  }

  .article_content_container .article_subheading {
    font-size: 20px;
  }
}


@media screen and (max-width:990px) {
  /* HOOME PAGE */
  #page-body .latest_post_container div, .welcome_section div {
    width:100%; /* The width is 100%, when the viewport is 990px or smaller */
  }

  #root .hamburger_icon{
    display: flex;
  }

  .App > nav {
    height: 54px;
  }

  .navBtn{
    display:none;
  }

  #page-body a.redirect_button {
    font-size: 15px;
  }

  .main_logo .dosoutddors_logo_home {
    width:70%; /* The width is 100%, when the viewport is 990px or smaller */
  }

  #page-body .welcome_right{
    padding: 20px 20px 20px 20px; 
  }

  #page-body .intro_section {
    box-shadow: none;
  }

  #page-body .aspectBox_text{
    font-size:40px;
  }

  /* ARTICLE PAGE */
  #root #page-body{
    width: 85%;
  }

  #page-body .article_content_container .article_paragraph, #page-body .article_content_container li, #page-body .article_content_container  .bold_article_paragraph {
    font-size: 15px;
  }

  .article_content_container .article_subheading {
    font-size: 30px;
  }

  #page-body .intro_blurb{
    font-size: 12px;
  }

  #page-body .responsive_hand_written_header{
    font-size: 37px;
  }

  #page-body .main_image_meta{
    font-size: 9px;
  }

}
@media screen and (min-width:1400px) {
  .padding_container{
    padding: 0 7% 0 7%;
  }
  .article-list-image {
    float: left;
    width: 45%;
    margin-bottom: 15px;
  }

  .article-item-description {
    float: right;
    width: 55%;
  }

  .article-list-item {
    text-decoration: none;
    border-bottom: 1px solid #e5e5e5;
    color: black;
    padding: 16px 0 0 0;
    display: inline-block;
  }
}


@media screen and (min-width:1600px) {
  .article-list-image {
    float: left;
    width: 45%;
    margin-bottom: 15px;
  }

  .article-item-description {
    float: right;
    width: 55%;
  }

  .article-list-item {
    text-decoration: none;
    color: black;
    padding: 16px 0 0 0;
    border-bottom: 1px solid #e5e5e5;
    display: inline-block;
}

  .main_logo .dosoutddors_logo_home {
    width:30%; /* The width is 30%, when the viewport is 1600px or larger */
  }
  #page-body .welcome_right .responsive_reading_font{
    font-size: 24px;
  }

  #page-body  .aspectBox_text{
    font-size:20px;
  }

  #page-body .article_content_container .article_paragraph, #page-body .article_content_container li, #page-body .article_content_container  .bold_article_paragraph {
    font-size: 18px;
  }

  .article_content_container .article_subheading {
    font-size: 35px;
  }

  
  
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*
Tutorial
*/

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
}

/* button {
  padding: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  background-color: black;
  color: white;
  border-radius: 8px;
  cursor: pointer;
} */

h3, h4 {
  margin-bottom: 0;
}

.App > nav {
  border-bottom: 1px solid #023144;;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
  display:block;
  z-index: 2;
  opacity: 0.9;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav li {
  display: inline-block;
  padding-top: 16px;
  padding-bottom: 16px;
  float: left;
}

nav a {
  text-decoration: none;
  color: #023144;
  font-weight: bold;
  padding: 20px 32px 17px 32px;
}

nav a:hover {
  /* background: black; */
  background: #023144;
  color: white;
}

#page-body {
  /* max-width: 700px; */
  margin: auto;
  padding-top: 64px;
  width: 68%;
}

.article-list-item {
  text-decoration: none;
  color: black;
  padding: 16px 0 0 0;
  border-bottom: 1px solid #e5e5e5;
}

.article-list-item p {
  padding-bottom: 40px;
  /* border-bottom: 1px solid #e5e5e5; */
}

#add-comment-form {
  border-bottom: 2px solid black;
  padding: 16px;
}

#add-comment-form h3 {
  margin-bottom: 16px;
}

#add-comment-form input, textarea {
  display: block;
  width: 300px;
  padding: 8px;
  border-radius: 8px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
  border: 2px solid black;
  margin-bottom: 16px;
}

#add-comment-form button {
  width: 320px;
}

#upvotes-section button, p {
  display: inline-block;
  margin-right: 16px;
}

.comment {
  border-bottom: 2px solid black;
  padding: 16px;
}

.article_main_image_element, img.single_articlkeImage{
  max-width: 100%;
  height: auto;
}

.main_image_meta{
  color: #666;
  font-size: 1.4vw;
  padding-bottom: 15px;
  font-family: 'Open Sans',sans-serif;
  text-align: left;
  font-style: italic;
}

.article_paragraph, .preview_par{
  font-family: 'Open Sans',sans-serif;
  line-height: 2em;
  color: #000000;
  text-align: left;
  /* font-size:1vw; */

  padding-bottom: 1em;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.home_main_image{
  width: 100%;
  background-repeat: no-repeat;
  background-image: url(https://imagestravelblog.s3.us-east-2.amazonaws.com/en_cover_picjpg.jpg);
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
  background-attachment: scroll;
  background-position: center bottom;
  border: none;
  float: left;
  height: 500px;
  background-size: cover;
  position: static;
}

.home_image_container{
  /* padding-top: 58px; */
  position: relative;
}

.main_logo_conatiner {
  padding: 30px 20px 0 20px;
  height: 94%;
  transition: opacity .6s ease-in;
  /* display: table; */
}

.main_logo {
  width: 100%;
  border: none;
  margin: auto;
  height: 89%;
  position: absolute;
}

.main_logo span{
  font-family: Charmonman;
  font-size: 50px;
  font-weight: bold;
  /* color: darksalmon; */
}

.App nav ul a {
  opacity: 1;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: bolder;
  text-transform: uppercase;
}

#root .hand_written_header{
  font-family: Reenie Beanie;
  font-size: 50px;
  color: #023144;
  margin: 22.5px 0px 33.5px 0px;
}

.responsive_hand_written_header{
  font-family: Reenie Beanie;
  font-size: 4.3vw;
  color: #023144;
}

.social_wrapper {
  width: auto;
  margin: 0;
  left: 30px;
  text-align: left;
  position: absolute;
}



.social_block {
  height: 100%;
  padding: 10px 4px 0 4px;
  float: right;
}
.social_block a {
  padding: 20px 12px 12px 12px;
}

.pinterestLogo{
  height: 28px;
  width:28px;
}

/* Latest Post Main Page */

.latest_post_container, .welcome_section {
  width: 100%;  
  position: relative;
  display: inline-block;
}

.latest_post_container > div{
  /* display: block; */
  float:left;
  width:31%;
  margin: 1%;
  padding-top:100%;
  padding: 10px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  /* height: 100%; */
}

.latest_post_container > div:hover{
  box-shadow: 0 0 30px rgb(0 0 0 / 20%);
}

.aspect_container{
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative;
}

.aspect_1_75{
  width: 100%;
  padding-top: 65%; /* 1:1 Aspect Ratio */
  position: relative;
}

.aspectBox{
  position: absolute;
  background-color: #333033;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: white;
  font-weight: bold;
  overflow: hidden;
}

* {
  box-sizing:border-box;
}

/* Logo */
img.dosoutddors_logo_home {
  width: 45%;
  opacity: 1;
  display: block;
  margin: auto;
}

.image_filter {
  opacity: 0.05;
  background-color: #a9a9a9;
  height: 500px;
}
.aspectBox img{
  width: 100%;

}

footer.dos_outdoors_footer {
  height: 200px;
  width: 100%;
  background-color: #023144;
  color: white;
  padding: 15px 20px;
  margin-top: 50px;
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
}

.intro_section {
  display: inline-block;
  margin-top: 15px;
}

a.redirect_button {
  background-color: #15495e;
  color: white;
  padding: 15px 25px;
  border-radius: 9px;
  font-family: 'Open Sans',sans-serif;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);

}

a.redirect_button:hover{
  background-color: white;
  color: #15495e;
}

.more_art_btn_cont {
  width: 100%;
  margin: 40px 0 11px 0;
  height: 30px;
}

.welcome_left{
  float: left;
  width:45%;  
  padding: 40px 25px 30px 25px;
}

.welcome_right{
  float: right;
  width:55%;
  padding: 20px 20px 20px 20px; 
}

.welcome_left .aspectBox{
  overflow: hidden;
  border-radius: 10000px;
  background-color: white;
}

.article_paragraph.responsive_reading_font{
  font-size: 100%;
  /* font-size: 1.7vw; */
}

.footer_left_section {
  float: left;
  width: 50%;
}
.footer_right_section {
  float: right;
  width: 50%;
}
footer p, footer a{
  font-size: 11px;
  margin: 10px 10px 10px 0;
  text-decoration: none;
  color:  white;
  width: 100%;
}

/* Hamburger Styling Start */

.hamburger_icon{
  /* position: absolute; */
    top: 5%;
    left: 2rem;
    
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0px;
    display: none;
    z-index: 10;
    float: right;
    margin: 0 15px;
}

.hamburger_icon div{
    width: 2rem;
    height: 0.25rem;
    background: #023144;
    border-radius: 10px;
    transition: all 0.3s linear 0s;
    position: relative;
    transform-origin: 1px center;
}

.cross .bb_1{
  transform:  rotate(45deg);
}

.cross .bb_2{
    opacity: 0;
}

.cross .bb_3{
  transform:  rotate(-45deg);
}

div#BurgerMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #EFFFFA;
  /* transform: ${({ open;}) => open ? 'translateX(0)' : 'translateX(-100%)';} height: 100vh; */
  text-align: left;
  padding: 2rem;
  position: relative;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  margin-top: 12px;
}

#BurgerMenu.invisMenu{
  /* transform: translateX(-100);
  transition: transform 0.3s ease-in-out; */
  display: none;
}

/* Hamburger Styling End */

.bold_article_paragraph{
  font-weight: bold;
  text-align: left;
  width: 100%;
  font-style: italic;
  margin-bottom: 5px;
}

ul.article_text_list {
  text-align: left;
  line-height: 2.9vw;
  line-height: 1.5em;
  padding-inline-start: 4.8vw;
  font-family: 'Open Sans',sans-serif;
}

h2.article_subheading {
  text-align: left;
  font-size: 38px;
  font-family: Reenie Beanie;
  color: #023144;
  margin: 50px 0 0px 0;
}

.intro_blurb{
  font-style: italic;
  font-size: 15px;
  margin-top: 22px;
}

.blurb_container {
  width: 96%;
  margin: 0 auto;
}

.article_content_container {
  max-width: 100%;
  margin: 0 auto;
}

ul.article_text_list.hollow_square_list li::before {
  content: "";
  background: white;
  padding-inline: 1.1vw;
  margin-inline-end: 1ch;
  border-bottom: 2px solid black;
  border-right: 3px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-radius: 27px
}

ul.article_text_list.hollow_square_list li{
  margin: 10px;
  list-style: none;
}

p.sideNote,  .sideNote span{
  font-family: 'Open Sans',sans-serif;
  font-size: 15px;
  font-weight: bolder;
}

.sideNote span{
  color: #023144;
}

a {
  color: #DC5318;
}

.normal-divider-spacing-below {
  height: 1px;
  width: 100%;
  background-color: #797979;
  margin: 10px 0 30px 0;
  opacity: 0.5;
}
.intro_section {
  display: inline-block;
  margin-top: 15px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

#root .welcome_right strong {
  font-family: Reenie Beanie;
  font-size: 40px;
  color: #023144;
}

.aspectBox_text {
  width: 100%;
  position: absolute;
  bottom: 0px;
  background-color: rgb(78 73 73 / 65%);
  padding: 5px 5px 5px 5px;
  font-size: 24px;
  font-family: 'Reenie Beanie';
}

.latest_post_container .aspectBox img {
  width: 193%;
  position: absolute;
  left: -45%;
  top: -30%;
}


/* Article Page responsive Text */
/* #page-body .article_content_container .article_paragraph, #page-body .article_content_container li, #page-body .article_content_container  .bold_article_paragraph {
  font-size: 1.8vw;
} */

/* .article_content_container .article_subheading {
  font-size: 3.7vw;
} */

.map_container {
  width: 70%;
  margin: 0 auto;
}

/* //Affiliate Style */
.amz_img_container {
  float: left;
  padding: 30px;
}

.sideNoteContainer {
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
  border-radius: 5px;
}

.article-item-description h3 {
  font-family: Reenie Beanie;
  font-size: 25px;
}